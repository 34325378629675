<template>
  <form @submit.prevent="onSubmit">
    <p v-if="isError" class="sign-up__error">
      Podane dane są nieprawidłowe
    </p>
    <p>
      <form-input
        v-model="$v.email.$model"
        label="e-mail"
        name="email"
        error-message="Podaj adres e-mail"
        :has-error="$v.email.$error && hasEmailBlurred"
        @blur="onEmailBlur"
      />
    </p>
    <p>
      <form-input
        v-model="password"
        :has-margin="false"
        :has-error="$v.password.$invalid && hasPasswordBlurred"
        error-message="Podaj hasło"
        label="hasło"
        name="password"
        type="password"
        @blur="onPasswordBlur"
      />
    </p>
    <p v-if="isError" class="sign-up__password-reset">
      Nie pamiętasz hasła?
      <router-link :to="{ name: 'password-reset-request' }">
        Zresetuj hasło
      </router-link>
    </p>
    <p class="sign-up__submit">
      <button
        class="button"
        type="submit"
        :disabled="!isFormValid"
        :class="{
          'button--loading': isLoading,
        }"
      >
        Zaloguj
      </button>
    </p>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import FormInput from '@/vue/components/user-attributes-form/form-input.vue';

export default {
  name: 'SignIn',

  components: { FormInput },

  mixins: [validationMixin],

  props: {
    redirectOnSuccess: {
      type: String,
      required: false,
      default: 'my-account',
    },
  },

  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },

  data: () => ({
    hasEmailBlurred: false,
    hasPasswordBlurred: false,
    email: '',
    password: '',
    isError: false,
    isLoading: false,
  }),

  computed: {
    isFormValid() {
      return !this.$v.email.$error && !this.$v.password.$error;
    },
  },

  created() {
    this.email = this.$route.query.user;
  },

  methods: {
    ...mapActions('user', ['signIn']),

    onEmailBlur() {
      this.hasEmailBlurred = true;
    },

    onPasswordBlur() {
      this.hasPasswordBlurred = true;
    },

    async onSubmit() {
      try {
        this.isLoading = true;
        this.isError = false;
        await this.signIn({ email: this.email, password: this.password });

        if (this.redirectOnSuccess) {
          this.$router.push({
            name: this.redirectOnSuccess,
          });
        }
      } catch (e) {
        this.isError = true;
        console.log('Unable to sign in', e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
